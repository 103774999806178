<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <router-link v-if="isAdmin" to="/create" class="btn btn-primary mb-2">Создать уведомление</router-link>
    <b-table
        hover
        :items="getFeedItemDataTable"
        :fields="fields"
        ref="feed_item_table"
        :per-page="feedItems.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Уведомления отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ row.item.id }}
      </template>
      <template v-slot:cell(name)="row">
        {{ (row.item.title != null && row.item.title !== "") ? row.item.title : ('ID: ' + row.item.id) }}
      </template>
      <template v-slot:cell(publish_status)="row">
        {{ getPublicationStatusMessage(row.item.publishStatus) }}
      </template>
      <template v-slot:cell(interaction)="row">
        <template v-if="!row.item.deleted">
          <b-button class="" variant="primary"
                    :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                    @click="prepareDetailPage(row.item)"
          >
            <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
            <b-icon-pencil v-else font-scale="0.75"></b-icon-pencil>
          </b-button>
          <b-button class="ml-1" variant="primary"
                    v-if="isAdmin"
                    title="Удалить"
                    @click="deleteFeedItem(row.item, true)"
          >
            <b-icon-trash font-scale="0.75"></b-icon-trash>
          </b-button>
        </template>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="feedItems.perPage"
            class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="feedItems.currentPage"
          class="my-0 ml-auto"
          :total-rows="feedItems.totalCount"
          :per-page="feedItems.perPage"
          aria-controls="template-table"
          @input="getFeedItemDataTable"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import feed from '../../modules/feed';
import {BIcon, BIconPencil, BIconPlus, BIconTrash, BIconDash} from 'bootstrap-vue';
import {mapGetters} from "vuex";

export default {
  name: 'feed-list',
  components: {
    'b-icon-pencil': BIconPencil,
    'b-icon-plus': BIconPlus,
    'b-icon-dash': BIconDash,
    'b-icon-trash': BIconTrash
  },
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Идентификатор'
        },
        {
          key: 'title', label: 'Заголовок'
        },
        {
          key: 'publish_status', label: 'Статус публикации'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      feedItems: {
        totalCount: 0,
        perPage: 20,
        currentPage: 1
      },
      curTime: moment(),
      selectedUserId: null
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin",
        "isRolesEmpty"
    ])
  },
  methods: {
    getFeedItemDataTable(context) {
      return feed.getFeedItemList(context.currentPage,
          context.perPage).then(resp => {
        this.feedItems.totalCount = resp.data.totalItems;
        this.curTime = moment();
        return resp.data.items;
      });
    },
    deleteFeedItem(item, promptModal) {
      let id = item.id;
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить уведомление?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              feed.deleteFeedItem(id)
                  .then(response => {
                    this.deleteError = null;
                    return this.$refs.feed_item_table.refresh();
                  })
                  .catch(error => {
                    this.deleteError = error;
                  });
            }
          })
          .catch(err => {
            // An error occurred
            this.deleteError = err;
          });
    },
    getPublicationStatusMessage(status) {
      let mapping = {
        'PUBLISHED': 'Опубликован',
        'PUBLISH_DELAY': 'Ожидает публикации',
        'NO_PUBLISH': 'Черновик',
      };
      return mapping[status];
    },
    prepareDetailPage(item) {
      this.$router.push(`/${item.id}`);
    }
  }
}
</script>
